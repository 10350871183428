<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>门店：</p>
          <select @change="getData(1)" v-model="form.fenpeimendianId">
            <option :value="0">全部</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>发送时间：</p>
          <input type="date" @change="getData(1)" v-model="form.startDate"><span> &nbsp;-&nbsp; </span>
          <input type="date" @change="getData(1)" v-model="form.endDate">
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <thead>
          <tr>
            <td style="text-align:center">时间</td>
            <td>门店</td>
            <td>手机号</td>
            <td style="text-align:center">类型</td>
            <td style="text-align:center">条数</td>
            <td>内容</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td style="text-align:center">{{dataFormat(item.shijian)}}</td>
            <td>{{item.fenpeimendianName}}</td>
            <td>{{item.phone}}</td>
            <td style="text-align:center"><span :class="returnLBClass(item.type)">{{returnLB(item.type)}}</span></td>
            <td style="text-align:center">{{item.num}}</td>
            <td :title="item.info">{{item.info}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import axios from '@/http/axios'
import moment from 'moment'
export default {
  name: 'OperationRecord',
  data() {
    return {
      dataList:[],
      storeList:[],
      form:{
        fenpeimendianId:this.$store.state.User.jibieId==0?0:this.$store.state.User.fenpeimendianId,
        startDate:'',
        endDate:'',
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
    }
  },
  created() {
     this.getData(1)
     if(this.$store.state.User.jibieId==0){
      this.getStore()
     }
  },
  components: {},
  computed:{
    countState(){
      return (state,time)=>{
        if(state==2||moment().diff(moment(time),'hours')>2){
          return false
        }else{
          return true
        }
      }
    }
  },
  mounted() {},
  methods: {
    getDingdanState(){
      this.ewmShow=false
      this.getData()
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getNameAndSon(name,sonId){
      let str=''
      const{son}=this.payData[name]
      str+=this.payData[name].name
      let sonObj=son.find(e=>e.id==sonId)
      if(sonObj){
        str+="-"+sonObj.name
      }
      return str
    },
    dataFormat(str){
      if(str) return moment(str).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    returnLBClass(lb){
      if(lb==1){
        return 'cal'
      }
      else if(lb==2){
        return 'pur'
      }
      else if(lb==3){
        return 'suc'
      }
    },
    returnLB(lb){
      if(lb==1){
        return '开通通知'
      }
      else if(lb==2){
        return '充值通知'
      }
      else if(lb==3){
        return '消费通知'
      }
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/pays/smsSendRecord',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }
      }).catch(() => {
        this.$Mesg({
          content: '获取数据失败！',
          errOrSuc: 'err',
        })
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    
  },
}
</script>
